import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  view_present: false,
};

const hallsListSlice = createSlice({
  name: "hallListSlice",
  initialState,
  reducers: {
    toggleViewPresent: (state) => {
      state.view_present = true;
    },
  },
});

export const { toggleViewPresent } = hallsListSlice.actions;
export default hallsListSlice.reducer;
