import { useGetUserByTgIDQuery } from "../../api/userAPI";
import MainHeader from "../../components/header/main/MainHeader";
import Navbar from "../../components/Navbar/Navbar";
import Loading from "../../modules/Loading/Loading";
import Menu from "../../modules/Menu/Menu";
import { lazy } from "react";
import { TG_ID } from "../../shared/constants/AppOptions";
import { useTelegram } from "../../shared/hooks/useTelegram";

const MenuPage = () => {
  const { webApp } = useTelegram();
  const {
    data: userData,
    isLoading: userLoadingData,
    isError: userErrorData,
  } = useGetUserByTgIDQuery(
    webApp?.initDataUnsafe?.user?.id
      ? webApp?.initDataUnsafe?.user?.id
      : Number(TG_ID)
  );

  if (userLoadingData) {
    return <Loading />;
  }

  if (userErrorData) {
    return <></>;
  }

  return <Menu />;
};

export default MenuPage;
