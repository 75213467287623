import MainHeader from "../components/header/main/MainHeader";
import Navbar from "../components/Navbar/Navbar";
import { Route, Routes } from "react-router-dom";
import MenuPage from "../pages/Menu/Menu";
import HallsPage from "../pages/Halls/HallsPage";
import OrderPage from "../pages/Order/Order";
import { usePathname } from "../shared/hooks/usePathname";
import HeaderWithNavbar from "../components/header/headerWithNavbar/HeaderWithNavbar";
import { useGetUserByTgIDQuery } from "../api/userAPI";
import { TG_ID } from "../shared/constants/AppOptions";
import { useTelegram } from "../shared/hooks/useTelegram";
const MainRoutes = () => {
  const pathname = usePathname();
  const { webApp } = useTelegram();
  const {
    data: userData,
    isLoading: userLoadingData,
    isError: userErrorData,
  } = useGetUserByTgIDQuery(
    webApp?.initDataUnsafe?.user?.id
      ? webApp?.initDataUnsafe?.user?.id
      : Number(TG_ID)
  );

  const func = () => {
    return pathname === "/menu" || pathname === "/" || pathname === "/order";
  };
  return (
    <>
      {func() && !userLoadingData && !userErrorData && <HeaderWithNavbar />}
      <Routes>
        <Route path="/menu" element={<MenuPage />} />
        <Route path="/" element={<HallsPage />} />
        <Route path="/order" element={<OrderPage />} />
      </Routes>
    </>
  );
};

export default MainRoutes;
