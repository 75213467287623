import StartBackgroundContainer from "../../components/StartBackground/StartBackground";
import LogoSVG from "../../shared/icons/LogoSVG";
import styles from "./styles.module.scss";
const Loading = () => {
  return (
    <StartBackgroundContainer className={styles.container}>
      <LogoSVG className={styles.logo} />

      <div className={styles.loading}>Загрузка...</div>
    </StartBackgroundContainer>
  );
};

export default Loading;
