export const saleValue = (booking_time: number) => {
  if (booking_time === 3) {
    return 7;
  }

  if (booking_time === 4) {
    return 9;
  }

  if (booking_time === 5) {
    return 12;
  }

  if (booking_time >= 6) {
    return 15;
  }

  return 0;
};
