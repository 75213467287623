import styles from "./styles.module.scss";
import bgImage from "../../shared/assets/BG.webp";
import LogoSVG from "../../shared/icons/LogoSVG";
import Form from "./components/form/Form";
import InputMask from "react-input-mask";
import { useState } from "react";
import NumberForm from "./components/NumberForm/NumberForm";
import SMSForm from "./components/SMSForm/SMSForm";
import { useNavigate } from "react-router-dom";
import useHistory from "../../shared/hooks/useHistory";
import { usePageAnimationClassName } from "../../shared/hooks/usePageAnimationClassName";
import StartBackgroundContainer from "../../components/StartBackground/StartBackground";

const NewUserForm = () => {
  const [step, setStep] = useState(0);
  const { navigate } = useHistory();
  const { updateAnimationClassName } = usePageAnimationClassName();

  const submitStep = () => {
    if (step === 0) {
      setStep(1);
      return;
    }

    updateAnimationClassName("slide");

    requestAnimationFrame(() => {
      navigate("/");
    });
  };

  return (
    <StartBackgroundContainer>
      <div className={styles.container}>
        <LogoSVG width={183} height={101} className={styles.logo} />

        {step === 0 ? (
          <NumberForm onSubmit={submitStep} />
        ) : (
          <SMSForm onSubmit={submitStep} />
        )}
      </div>
    </StartBackgroundContainer>
  );
};

export default NewUserForm;
