import { FC } from "react";
import { BaseMenuListInterface } from "../../models/BaseListInterface";
import { ServiceListItemModel } from "../../../../api/models/serviceListApiModel";
import SkeletonCard from "../../../../ui/SkeletonCard/SkeletonCard";
import AnimatedCard from "../AnimatedCard/AnimatedCard";
import StyledMenuCard from "../../../../ui/MenuCard/MenuCard";
import MenuList from "../MenuList/MenuList";
import {
  useGetUserByTgIDQuery,
  useLazyUpdateCartQuery,
  userApiSlice,
} from "../../../../api/userAPI";
import { TG_ID } from "../../../../shared/constants/AppOptions";
import { useAppDispatch } from "../../../../shared/hooks/reduxTypes";
import { useTelegram } from "../../../../shared/hooks/useTelegram";

const ServiceList: FC<BaseMenuListInterface<ServiceListItemModel[]>> = ({
  isLoading,
  isError,
  data,
}) => {
  const { data: userData } = useGetUserByTgIDQuery(Number(TG_ID));
  const { webApp } = useTelegram();
  const dispatch = useAppDispatch();
  const [updateCart] = useLazyUpdateCartQuery();
  if (isLoading) {
    return (
      <MenuList>
        <SkeletonCard />
        <SkeletonCard />
        <SkeletonCard />
        <SkeletonCard />
        <SkeletonCard />
        <SkeletonCard />
      </MenuList>
    );
  }
  return (
    <MenuList>
      {data &&
        data.map((el) => {
          return (
            <AnimatedCard key={el.id}>
              <StyledMenuCard
                image={el.photo}
                price={el.price}
                addInfo={` ${el.for_time} мин.`}
                name={el.name}
                is_busket={
                  userData &&
                  userData?.data &&
                  userData.data?.services &&
                  userData?.data?.services[String(el.id)]
                    ? true
                    : false
                }
                onClickPrice={() => {
                  if (
                    userData &&
                    userData?.data?.services &&
                    !userData?.data?.services[String(el.id)]
                  ) {
                    const update = userApiSlice.util.updateQueryData(
                      "getUserByTgID",
                      webApp?.initDataUnsafe?.user?.id
                        ? webApp?.initDataUnsafe?.user?.id
                        : Number(TG_ID),
                      (draft) => {
                        const cartData = draft.data;

                        cartData.services[String(el.id)] = {
                          quantity: 1,
                          price: el.price,
                          time: el.for_time,
                          name: el.name,
                          photo: el.photo,
                        };

                        return draft;
                      }
                    );
                    dispatch(update);

                    const data = JSON.parse(JSON.stringify(userData.data));

                    if (data.services) {
                      data.services[String(el.id)] = {
                        quantity: 1,
                        price: el.price,
                        time: el.for_time,
                        name: el.name,
                        photo: el.photo,
                      };
                    }

                    if (userData?.id) {
                      updateCart({ user_id: userData.id, data: data });
                    }
                  }
                }}
              />
            </AnimatedCard>
          );
        })}
    </MenuList>
  );
};

export default ServiceList;
