import { usePageAnimationClassName } from "../../../../shared/hooks/usePageAnimationClassName";
import FormSubmitButton from "../../../../ui/FormSubmitButton/FormSubmitButton";
import { NewUserFormProps } from "./interface";
import styles from "./styles.module.scss";
import { FC } from "react";
import { useRef, useEffect } from "react";
const Form: FC<NewUserFormProps> = ({
  descriptionText,
  headerText,
  children,
  onSubmit,
}) => {
  const headerRef = useRef<HTMLDivElement>(null);
  const descRef = useRef<HTMLDivElement>(null);


  useEffect(() => {
    if (headerRef.current) {
      headerRef.current.style.opacity = "0";
      headerRef.current.style.transform = "scale(0.5)";
      setTimeout(() => {
        if (headerRef.current) {
          headerRef.current.style.opacity = "1";
          headerRef.current.style.transform = "scale(1)";
        }
      }, 100); // Время анимации, должно совпадать с CSS
    }
  }, [headerText]);

  useEffect(() => {
    if (descRef.current) {
      descRef.current.style.opacity = "0";
      descRef.current.style.transform = "scale(0.5)";
      setTimeout(() => {
        if (descRef.current) {
          descRef.current.style.opacity = "1";
          descRef.current.style.transform = "scale(1)";
        }
      }, 200); // Время анимации, должно совпадать с CSS
    }
  }, [descriptionText]);
  return (
    <form onSubmit={onSubmit} className={styles.form}>
      <div ref={headerRef} className={styles.formHeader}>
        {headerText}
      </div>

      <div ref={descRef} className={styles.formDesc}>
        {descriptionText}
      </div>

      {children}

      <FormSubmitButton
        type="submit"
        className={styles.submitBtn}
        onClick={() => {}}
      >
        ПРОДОЛЖИТЬ
      </FormSubmitButton>
    </form>
  );
};

export default Form;
