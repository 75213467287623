import SectionHeader from "../../components/SectionHeader/SectionHeader";
import Description from "./components/Description/Description";
import Info from "./components/Info/Info";
import styles from "./styles.module.scss";
import Form from "./components/Form/HallForm";
import HallImages from "./components/Images/HallImages";
import { SwiperSlide, Swiper } from "swiper/react";
import { useTheme } from "../../shared/hooks/useTheme";
import { useSpring, animated } from "@react-spring/web";

import { useParams } from "react-router-dom";
import SkeletonLoader from "./components/SkeletonLoader/SkeletonLoader";
import { useState } from "react";
import { useGetHallByIdQuery } from "../../api/hallsAPI";
const HallForm = () => {
  const [{ opacity, scale }, api] = useSpring(() => ({
    from: { opacity: 0, scale: 0.5 }, // Начальные значения
    to: { opacity: 1, scale: 1 }, // Конечные значения
    config: { duration: 500 }, // Настройки анимации
  }));

  const params = useParams();

  const { data, isLoading, isError, error } = useGetHallByIdQuery(`${params.id}`);

  if (isLoading) {
    return <SkeletonLoader />;
  }

  return (
    <div className={styles.superContainer}>
      <animated.div style={{ opacity, scale }} className={styles.container}>
        <SectionHeader>{data?.name}</SectionHeader>
      </animated.div>

      <HallImages images={data?.photo} />

      <div className={`${styles.container} ${styles.grow}`}>
        <>
          <Description text={data?.desc ? data?.desc : ""} />
          <Info
            capacity={data?.max_people !== undefined ? data?.max_people : -1}
            status={
              <>
                <span>
                  <strong>
                    {data?.status === "FR" ? "Cвободно" : "Занято"}
                  </strong>{" "}
                  прямо сейчас!
                </span>
              </>
            }
          />
        </>
      </div>

      <Form />
    </div>
  );
};

export default HallForm;
