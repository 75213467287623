import { FC, useEffect, useState } from "react";
import SwipeableDrawerComponent from "../../../../components/SwipebleDrawer/SwipeableDrawer";
import { GiftModalProps } from "./interface";
import styles from "./styles.module.scss";
import MenuCard from "../../../../ui/MenuCard/MenuCard";
import contImage from "../../../../shared/assets/BG.webp";
import FormSubmitButton from "../../../../ui/FormSubmitButton/FormSubmitButton";
import { createPortal } from "react-dom";
import { useAppDispatch } from "../../../../shared/hooks/reduxTypes";
import { toggleViewPresent } from "../../slices/HallsListSlice";
import { useGetPresentQuery } from "../../../../api/userAPI";
const GiftModal: FC<GiftModalProps> = ({ open, onClose, onOpen }) => {
  const dispatch = useAppDispatch();

  const { data: presentData, isLoading: presentLoading } = useGetPresentQuery();

  useEffect(() => {
    if (open) {
      dispatch(toggleViewPresent());
    }
  }, [open]);

  const getWeight = () => {
    return presentData && presentData[0] && presentData[0]?.present.weight
      ? String(presentData[0]?.present.weight)
      : "";
  };

  if (presentLoading) {
    return <></>;
  }

  return (
    <>
      <SwipeableDrawerComponent
        onClose={onClose}
        onOpen={onOpen}
        open={open}
        header="ПОДАРОК"
      >
        <div className={styles.container}>
          <span className={styles.text}>
            Спасибо за регистрацию! Ваш подарок:
          </span>

          <MenuCard
            image={
              presentData && presentData[0] && presentData[0]?.present?.photo
                ? presentData[0]?.present?.photo
                : ""
            }
            className={styles.card}
            name={
              presentData && presentData[0] && presentData[0]?.present?.name
                ? presentData[0]?.present?.name
                : ""
            }
            addInfo={`(${getWeight()}г.)`}
            is_busket={false}
          />

          <FormSubmitButton
            className={styles.continue}
            onClick={onClose}
            type="button"
          >
            ПРОДОЛЖИТЬ
          </FormSubmitButton>
        </div>
      </SwipeableDrawerComponent>
    </>
  );
};

export default GiftModal;
