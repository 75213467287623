import { useGetUserByTgIDQuery } from "../../api/userAPI";
import MainHeader from "../../components/header/main/MainHeader";
import Navbar from "../../components/Navbar/Navbar";
import Loading from "../../modules/Loading/Loading";
import Order from "../../modules/Order/Order";
import { TG_ID } from "../../shared/constants/AppOptions";
import { useTelegram } from "../../shared/hooks/useTelegram";
import { useTheme } from "../../shared/hooks/useTheme";

const OrderPage = () => {
  const { webApp } = useTelegram();
  const {
    data: userData,
    isLoading: userLoadingData,
    isError: userErrorData,
  } = useGetUserByTgIDQuery(
    webApp?.initDataUnsafe?.user?.id
      ? webApp?.initDataUnsafe?.user?.id
      : Number(TG_ID)
  );

  if (userLoadingData) return <Loading />;

  if (userErrorData) {
    return <></>;
  }

  return <Order />;
};

export default OrderPage;
