import { useCallback, useEffect } from "react";
import { useTelegram } from "./useTelegram";

const useBackButton = (onClick: () => void) => {
  const { webApp } = useTelegram();

  useEffect(() => {
    webApp.BackButton.show();
    webApp.BackButton.onClick(onClick);

    return () => {
      webApp.BackButton.offClick(onClick);
      webApp.BackButton.hide();
    };
  }, [onClick]);
};

export default useBackButton;
