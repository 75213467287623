import { Button } from "@mui/material";
import styles from "./styles.module.scss";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { FC } from "react";
import { CounterEditorProps } from "./interface";
import { useTheme } from "../../shared/hooks/useTheme";
const CounterEditor: FC<CounterEditorProps> = ({
  className,
  minusBtnClick,
  plusBtnClick,
  counterBlockChildren,
}) => {
  const { theme } = useTheme();

  return (
    <div data-theme={theme} className={`${styles.top} ${className}`}>
      <Button
        color="error"
        sx={{ border: "2px solid var(--main-theme-color)" }}
        variant="outlined"
        onClick={minusBtnClick}
      >
        {" "}
        <RemoveIcon />
      </Button>

      <div className={styles.counter}>{counterBlockChildren}</div>

      <Button
        sx={{ border: "2px solid var(--main-theme-color)" }}
        variant="outlined"
        onClick={plusBtnClick}
      >
        {" "}
        <AddIcon />
      </Button>
    </div>
  );
};

export default CounterEditor;
