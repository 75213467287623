import SectionHeader from "../../components/SectionHeader/SectionHeader";
import styles from "./styles.module.scss";
import InfoListBlock from "../../components/InfoListBlock/InfoListBlock";
import HumanSVG from "../../shared/icons/HumanSVG";
import PhoneSVG from "../../shared/icons/PhoneSVG";
import {
  Button,
  ButtonGroup,
  ToggleButtonGroup,
  ToggleButton,
  Alert,
  Slide,
  Fade,
  AlertTitle,
} from "@mui/material";
import EditSVG from "../../shared/icons/EditSVG";
import ProfileHistory from "./components/History/ProfileHistory";
import CopySVG from "../../shared/icons/CopySVG";
import { useNavigate } from "react-router-dom";
import CheckIcon from "@mui/icons-material/Check";
import { AlertComponent } from "../../ui/Alert/Alert";
import { useEffect, useState } from "react";
import CheckMarkSVG from "../../shared/icons/CheckMarkSVG";
import { createPortal } from "react-dom";
import { useRef } from "react";
import useVibration from "../../shared/hooks/useVibration";
import Checkbox from "@mui/material/Checkbox";
import CheckboxComponent from "../../ui/CheckBox/Checkbox";
import { useTheme } from "../../shared/hooks/useTheme";
import { CSSTransition } from "react-transition-group";
import "./styles.scss";
import useHistory from "../../shared/hooks/useHistory";
import { useSpring, animated } from "@react-spring/web";
import { useGetUserByTgIDQuery } from "../../api/userAPI";
import { TG_ID } from "../../shared/constants/AppOptions";
import Support from "./components/Support/Support";
import ToggleTheme from "./components/ToggleTheme/ToggleTheme";
import { useTelegram } from "../../shared/hooks/useTelegram";
const Profile = () => {
  const { webApp } = useTelegram();
  const { navigate } = useHistory();
  const copyTimerRef = useRef<NodeJS.Timeout | null>(null);
  const [copyShow, setCopyShow] = useState(false);
  const [historyShow, setHistoryShow] = useState(false);

  const { theme, setValueTheme } = useTheme();

  useEffect(() => {
    return () => {
      if (copyTimerRef.current) {
        clearTimeout(copyTimerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    requestAnimationFrame(() => {
      setHistoryShow(true);
    });
  }, []);

  const handleCopy = async () => {
    setCopyShow(true);
    if (copyTimerRef.current) {
      clearTimeout(copyTimerRef.current);
    }
    copyTimerRef.current = setTimeout(() => {
      setCopyShow(false);
    }, 2000);
  };

  const {
    data: userData,
    isLoading: userLoadingData,
    isError: userErrorData,
  } = useGetUserByTgIDQuery(
    webApp?.initDataUnsafe?.user?.id
      ? webApp?.initDataUnsafe?.user?.id
      : Number(TG_ID)
  );

  const formatRegDate = () => {
    if (userData?.reg_date) {
      const [year, month, day] = userData.reg_date.split("-");

      const formattedDay = day.padStart(2, "0");
      const formattedMonth = month.padStart(2, "0");

      return `${formattedDay}.${formattedMonth}.${year}`;
    }
    return "";
  };

  return (
    <>
      <div data-theme={theme} className={styles.container}>
        <animated.div>
          <SectionHeader className={styles.sectionProfName}>
            ВАШ ПРОФИЛЬ:
          </SectionHeader>
        </animated.div>

        <InfoListBlock className={styles.profileInfo}>
          <>
            <li>
              <HumanSVG className={styles.humanSVG} />{" "}
              <span>
                <strong>Регистрация</strong> {formatRegDate()}
              </span>
            </li>

            <li className={styles.phone}>
              <PhoneSVG className={styles.phoneSVG} />
              <span className={styles.text}>{userData?.phone_number}</span>
              <Button
                onClick={() => {
                  navigate("/register");
                }}
              >
                <EditSVG />
              </Button>
            </li>
          </>
        </InfoListBlock>

        <SectionHeader className={styles.sectionToggleTheme}>
          ТЕМА:
        </SectionHeader>

        <ToggleTheme />

        <SectionHeader className={styles.sectionHeaderHistory}>
          ИСТОРИЯ ПОСЕЩЕНИЙ:
        </SectionHeader>
      </div>

      {historyShow && <ProfileHistory />}

      <Support copyClick={handleCopy} />

      {createPortal(
        <Slide
          style={{
            position: "fixed",
            top: 30,
            left: 20,
            right: 20,
            width: "auto",
            zIndex: 16,
          }}
          timeout={300}
          direction="down"
          in={copyShow}
          mountOnEnter
          unmountOnExit
        >
          <AlertComponent severity="success">
            <>
              <AlertTitle className={styles.alertTitle}>Скопировано</AlertTitle>
            </>
          </AlertComponent>
        </Slide>,
        document.body
      )}
    </>
  );
};

export default Profile;
