import { FC, useEffect, useState } from "react";
import SliderComponent from "../../../../ui/Slider/Slider";
import styles from "./styles.module.scss";
import CustomDatePicker from "../../../../components/CustomDatePicker/CustomDatePicker";
import FormSubmitButton from "../../../../ui/FormSubmitButton/FormSubmitButton";
import SwipeableDrawerComponent from "../../../../components/SwipebleDrawer/SwipeableDrawer";
import { AutorenewRounded } from "@mui/icons-material";
import { FilterFormProps } from "./interface";
import TimeInput from "../../../../components/Inputs/TimeInput/TimeInput";
import SelectComponent from "../../../../ui/Select/Select";
import { SORTOPTIONS } from "../../../../shared/constants/SelectOptions";
import { useDebouncedCallback } from "use-debounce";
import React from "react";
import ColumnForm from "../ColumnForm/ColumnForm";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../shared/hooks/reduxTypes";
import {
  setPriceValue,
  setCapacityValue,
  clearForm,
  setStartDate,
  setReqValues,
} from "../../slices/FilterSlice";
import { useGetDateTimeQuery } from "../../../../api/userAPI";
import { setDate } from "../../slices/FilterSlice";
import {
  useGetFilteredHallsMutation,
  useLazyGetHallsQuery,
} from "../../../../api/hallsAPI";
const FilterForm: FC<FilterFormProps> = ({ open, onClose, onOpen }) => {
  const {
    data: nowDate,
    isLoading: isLoadingDate,
    isError: isErrorDate,
  } = useGetDateTimeQuery(undefined);
  const capacity = useAppSelector(
    (state) => state.hallsFilterForm.capacityValue
  );
  const price = useAppSelector((state) => state.hallsFilterForm.priceValue);
  const dateValue = useAppSelector((state) => state.hallsFilterForm.date);
  const sort = useAppSelector((state) => state.hallsFilterForm.sortValue);
  const hour = useAppSelector((state) => state.hallsFilterForm.hour);
  const minute = useAppSelector((state) => state.hallsFilterForm.minute);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (nowDate) {
      dispatch(setStartDate(nowDate.datetime));
    }
  }, [nowDate]);

  useEffect(() => {
    if (isErrorDate) dispatch(setStartDate(new Date().toISOString()));
  }, [isErrorDate]);

  return (
    <>
      <SwipeableDrawerComponent
        onClose={onClose}
        onOpen={onOpen}
        header="ФИЛЬТРЫ"
        leftHeaderButton={<AutorenewRounded />}
        leftHeaderButtonClick={() => {
          dispatch(clearForm());
        }}
        open={open}
      >
        <div className={styles.form}>
          <SliderComponent
            name="Вместимость: "
            valueChild={
              capacity === 0 ? "Не имеет значения" : `До ${capacity} человек`
            }
            onChange={(e, value) => {
              if (typeof value === "number") {
                dispatch(setCapacityValue(value));
              }
            }}
            min={0}
            max={100}
            value={capacity}
          />
          <SliderComponent
            name="Цена за час: "
            valueChild={price === 0 ? "Не имеет значения" : `До ${price} руб.`}
            onChange={(e, value) => {
              if (typeof value === "number") {
                dispatch(setPriceValue(value));
              }
            }}
            min={0}
            max={15000}
            value={price}
          />

          <div className={styles.label}>Дата посещения:</div>

          <CustomDatePicker
            dateValue={new Date(dateValue)}
            onChangeDate={(date) => {
              if (date) {
                dispatch(
                  setDate(
                    date.toDateString() !== new Date(dateValue).toDateString()
                      ? `${date.getFullYear()}-${
                          date.getMonth() + 1
                        }-${date.getDate()}`
                      : ""
                  )
                );
              }
            }}
            className={styles.datepicker}
          />

          <ColumnForm />

          <FormSubmitButton
            className={styles.submitBtn}
            onClick={(e) => {
              dispatch(setReqValues());
              onClose(e);
            }}
            type="button"
          >
            Применить
          </FormSubmitButton>
        </div>
      </SwipeableDrawerComponent>
    </>
  );
};

export default React.memo(FilterForm);
