import { FC, useRef, useEffect, useState } from "react";
import { HallsHeaderProps } from "./interface";
import styles from "./styles.module.scss";
import { Button } from "@mui/material";
import SettingsSVG from "../../../../shared/icons/SettingSVG";

const HallsHeader: FC<HallsHeaderProps> = ({ onClickSettings }) => {
  const [firstRender, setFirstRender] = useState(false);
  const textRef = useRef<HTMLSpanElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    setFirstRender(true);
    if (textRef.current && buttonRef.current) {
      textRef.current.style.transform = "translateX(-20px)"; // Start offscreen
      textRef.current.style.transition = "transform 0.25s ease-in-out";
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          if (textRef.current)
            textRef.current.style.transform = "translateX(0px)"; // Animate to normal position
        });
      });

      buttonRef.current.style.transform = "translateX(20px)"; // Start offscreen
      buttonRef.current.style.transition = "transform 0.25s ease-in-out";
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          if (buttonRef.current)
            buttonRef.current.style.transform = "translateX(0px)"; // Animate to normal position
        });
      });
    }
  }, [firstRender]);

  return (
    <div className={styles.cont}>
      <span ref={textRef} className={styles.text}>
        НАШИ ЗАЛЫ
      </span>
      <Button
        ref={buttonRef}
        sx={{ width: 30, height: 30, padding: 0 }}
        onClick={onClickSettings}
        className={styles.settingBtn}
      >
        <SettingsSVG />
      </Button>
    </div>
  );
};

export default HallsHeader;
