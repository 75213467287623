export function getHourForm(hour: number): string {
  if (hour % 10 === 1 && hour % 100 !== 11) {
    return "час";
  } else if (
    hour % 10 >= 2 &&
    hour % 10 <= 4 &&
    (hour % 100 < 10 || hour % 100 >= 20)
  ) {
    return "часа";
  } else {
    return "часов";
  }
}

export const calcPrice = (hour: number, price: number) => {
  return hour * price;
};
