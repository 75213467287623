import { Button } from "@mui/material";
import React, { useState, useEffect, memo } from "react";
import styles from "./styles.module.scss";
import { CustomDatePickerProps } from "./interface";
import { useGetDateTimeQuery } from "../../api/userAPI";

const dayOfWeek = ["ПН", "ВТ", "СР", "ЧТ", "ПТ", "СБ", "ВС"];

const CustomDatePicker: React.FC<CustomDatePickerProps> = ({
  className,
  dateValue,
  onChangeDate,
}) => {
  const { data: nowDate, isLoading, isError } = useGetDateTimeQuery(undefined);

  const [currentDate, setCurrentDate] = useState<Date | null>(null);
  const [dates, setDates] = useState<Date[]>([]);

  useEffect(() => {
    if (nowDate) {
      setCurrentDate(new Date(nowDate.datetime));
    }
  }, [nowDate]);

  useEffect(() => {
    generateDates();
  }, [currentDate]);

  const getStartOfWeek = (date: Date): Date => {
    const day = date.getDay();
    const diff = (day === 0 ? -6 : 1) - day; // Приводим к понедельнику
    const monday = new Date(date);
    monday.setDate(date.getDate() + diff);
    monday.setHours(0, 0, 0, 0); // Устанавливаем время на 00:00:00
    return monday;
  };

  const generateDates = () => {
    if (currentDate) {
      const startOfWeek = getStartOfWeek(currentDate);
      const newDates: Date[] = [];

      for (let i = 0; i < 4; i++) {
        for (let j = 0; j < 7; j++) {
          const date = new Date(startOfWeek);
          date.setDate(startOfWeek.getDate() + i * 7 + j);
          date.setHours(0, 0, 0, 0); // Устанавливаем время на 00:00:00
          newDates.push(date);
        }
      }

      setDates(newDates);
    }
  };

  const isDateDisabled = (date: Date) => {
    if (
      currentDate &&
      date < currentDate &&
      date.toDateString() !== new Date().toDateString()
    ) {
      return true;
    }

    return false;
  };

  const classNameForDate = (date: Date) => {
    const curDate = nowDate?.datetime ? new Date(nowDate.datetime) : new Date();
    
    return `${styles.date} ${
      date < curDate && date.toDateString() !== curDate.toDateString()
        ? styles.pastDate
        : ""
    } ${
      dateValue &&
      date.toDateString() === dateValue.toDateString() &&
      styles.active
    }`;
  };

  return (
    <div className={`${styles.container} ${className}`}>
      <div className={styles.weekDays}>
        {dayOfWeek.map((day) => (
          <div className={styles.weekDay} key={day}>
            {day}
          </div>
        ))}
      </div>
      <div className={styles.dates}>
        {dates.map((date) => (
          <Button
            disabled={isDateDisabled(date)}
            className={classNameForDate(date)}
            key={date.toString()}
            onClick={() => {
              onChangeDate(date);
            }}
            sx={{ width: 34, height: 34, minHeight: 0, minWidth: 0 }}
          >
            {date.getDate()}
          </Button>
        ))}
      </div>
    </div>
  );
};

export default memo(CustomDatePicker);
