import { Card, CardMedia } from "@mui/material";
import { HallCardProps } from "./interface";
import { FC } from "react";
import styles from "./styles.module.scss";
const HallCard: FC<HallCardProps> = ({
  className,
  image,
  mediaChild,
  children,
  cardMediaClassName,
}) => {
  return (
    <Card className={`${styles.card} ${className}`}>
      {image && (
        <CardMedia
          image={image}
          className={`${styles.media} ${cardMediaClassName}`}
        >
          {mediaChild}
        </CardMedia>
      )}
      {children}
    </Card>
  );
};

export default HallCard;
