import { Button } from "@mui/material";
import { useTheme } from "../../../../../shared/hooks/useTheme";
import Count from "./Count/Count";
import { OrderCardProps } from "./interface";
import styles from "./styles.module.scss";
import { FC } from "react";
import DeleteOutlineTwoToneIcon from "@mui/icons-material/DeleteOutlineTwoTone";
import { useSwipeable } from "react-swipeable";
import { useRef } from "react";
import { useTelegram } from "../../../../../shared/hooks/useTelegram";
import { SwipeCallback } from "react-swipeable";
import { transformPathToPhoto } from "../../../../../shared/functions/transformPathToPhoto";
const  OrderCard: FC<OrderCardProps> = ({
  image,
  count,
  name,
  className,
  price,
  plusClick,
  minusClick,
  onClickDelete,
}) => {
  const { theme } = useTheme();
  const deleteButtonRef = useRef<HTMLButtonElement>(null);
  const startXRightSlide = useRef<null | number>(null);
  const startXLeftSlide = useRef<null | number>(null);
  const lastDeltaX = useRef<null | number>(null);
  const { webApp } = useTelegram();

  const onSwipedFunc: SwipeCallback = (eventData) => {
    const { vxvy } = eventData;

    if (vxvy[0] < -0.5) {
      if (deleteButtonRef.current) {
        deleteButtonRef.current.style.transition = "0.1s linear";
        requestAnimationFrame(() => {
          if (deleteButtonRef.current)
            deleteButtonRef.current.style.width = "40px";
        });
      }

      return;
    }

    if (vxvy[0] > 0.5) {
      if (deleteButtonRef.current) {
        deleteButtonRef.current.style.transition = "0.1s linear";
        requestAnimationFrame(() => {
          if (deleteButtonRef.current)
            deleteButtonRef.current.style.width = "0px";
        });
      }

      return;
    }

    if (deleteButtonRef.current && deleteButtonRef.current?.offsetWidth >= 20) {
      deleteButtonRef.current.style.transition = "0.1s linear";
      requestAnimationFrame(() => {
        if (deleteButtonRef.current)
          deleteButtonRef.current.style.width = "40px";
      });
    }

    if (deleteButtonRef.current && deleteButtonRef.current?.offsetWidth < 20) {
      deleteButtonRef.current.style.transition = "0.1s linear";
      requestAnimationFrame(() => {
        if (deleteButtonRef.current)
          deleteButtonRef.current.style.width = "0px";
      });
    }
  };

  const onSwipeStartFunc: SwipeCallback = (event) => {
    lastDeltaX.current = 0;
    if (event.dir === "Right") {
      startXRightSlide.current = event.initial[0];
      return;
    }
    if (event.dir === "Left") {
      startXLeftSlide.current = event.initial[0];

      return;
    }
  };

  const onSwipingFunc: SwipeCallback = (event) => {
    if (lastDeltaX.current !== null) {
      if (event.deltaX < lastDeltaX.current) {
        startXLeftSlide.current = event.initial[0] + event.deltaX;
        if (deleteButtonRef.current) {
          deleteButtonRef.current.style.width = `${
            deleteButtonRef.current.offsetWidth +
            Math.abs(event.deltaX - lastDeltaX.current)
          }px`;
        }
      }
      if (event.deltaX > lastDeltaX.current) {
        startXRightSlide.current = event.initial[0] + event.deltaX;

        if (deleteButtonRef.current) {
          deleteButtonRef.current.style.width = `${Math.max(
            deleteButtonRef.current.offsetWidth -
              Math.abs(event.deltaX - lastDeltaX.current),
            0
          )}px`;
        }
      }

      lastDeltaX.current = event.deltaX;
    }
  };

  const handlers = useSwipeable({
    onSwiped:
      webApp.platform === "android" || webApp.platform === "ios"
        ? onSwipedFunc
        : undefined,
    onSwipeStart:
      webApp.platform === "android" || webApp.platform === "ios"
        ? onSwipeStartFunc
        : undefined,

    onSwiping:
      webApp.platform === "android" || webApp.platform === "ios"
        ? onSwipingFunc
        : undefined,
  });
  return (
    <div {...handlers} className={styles.container}>
      <div data-theme={theme} className={`${styles.card} ${className}`}>
        <img className={styles.image} src={transformPathToPhoto(image)} alt={`image ${name}`} />
        <div className={styles.info}>
          <span className={styles.name}>{name}</span>
          <span className={styles.price}>{price}р</span>
        </div>

        {count !== undefined && (
          <Count count={count} plusClick={plusClick} minusClick={minusClick} />
        )}
      </div>

      <Button
        onTransitionEnd={() => {
          if (deleteButtonRef.current) {
            deleteButtonRef.current.style.transition = "";
          }
        }}
        onClick={(e) => {
          if (onClickDelete) onClickDelete(e);
        }}
        style={{ padding: 0 }}
        ref={deleteButtonRef}
        className={`${styles.deleteBtn} ${
          webApp.platform === "ios" || webApp.platform === "android"
            ? styles.mobile
            : ""
        }`}
      >
        <DeleteOutlineTwoToneIcon />
      </Button>
    </div>
  );
};

export default OrderCard;
