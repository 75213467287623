import { FC } from "react";
import { BaseComponentInterface } from "../../../../shared/models/BaseComponentInterface";
import { animated, useSpring } from "@react-spring/web";
const AnimatedCard: FC<BaseComponentInterface> = ({ className, children }) => {
  const [{ opacity, scale }, api] = useSpring(() => ({
    from: { opacity: 0, scale: 0.5 }, // Начальные значения
    to: { opacity: 1, scale: 1 }, // Конечные значения
    config: { duration: 300, decay: 300 }, // Настройки анимации
  }));

  return <animated.div style={{ opacity, scale }}>{children}</animated.div>;
};

export default AnimatedCard;
