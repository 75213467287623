import styles from "./styles.module.scss";
import bgImage from "../../../shared/assets/BG.webp";
import LogoSVG from "../../../shared/icons/LogoSVG";
import { Avatar } from "@mui/material";
import { useTelegram } from "../../../shared/hooks/useTelegram";
import { useEffect } from "react";
import { useSpring, animated } from "@react-spring/web";
import { useGetUserByTgIDQuery } from "../../../api/userAPI";
import { TG_ID } from "../../../shared/constants/AppOptions";
import { transformPathToPhoto } from "../../../shared/functions/transformPathToPhoto";

const HeaderProfile = () => {
  const { webApp } = useTelegram();

  // Хук для анимации
  const { transform } = useSpring({
    from: { transform: "translateY(-100%)" }, // Начальные значения
    to: { transform: "translateY(0)" }, // Конечные значения
    config: { tension: 200, friction: 20 }, // Настройки анимации
  });

  const { data } = useGetUserByTgIDQuery(
    webApp?.initDataUnsafe?.user?.id
      ? webApp?.initDataUnsafe?.user?.id
      : Number(TG_ID)
  );


  return (
    <animated.header className={styles.header} style={{ transform }}>
      <div className={styles.bgCont}>
        <div className={styles.bg}>
          <img src={bgImage} className={styles.bg} alt={"bg"} />
        </div>
      </div>

      <div className={styles.container}>
        <span className={styles.name}>
          {webApp?.initDataUnsafe?.user?.first_name}
        </span>
        <LogoSVG />
      </div>

      <Avatar
        src={transformPathToPhoto(`${data?.photo}`)}
        className={styles.avatar}
      />
    </animated.header>
  );
};

export default HeaderProfile;
