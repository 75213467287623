import { BASE_URL_API } from "../constants/AppOptions";

function isValidUrl(url: string) {
  const regex =
    /^(https?:\/\/)?(www\.)?((localhost|[a-zA-Z0-9-]+\.[a-zA-Z]{2,}|[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}|[a-zA-Z0-9-]+)(:[0-9]+)?)(\/[^\s]*)?$/;
  return regex.test(url);
}

export const transformPathToPhoto = (path: string) => {

  return `${isValidUrl(path) ? "" : BASE_URL_API}${path}`;
};
