import styles from "./styles.module.scss";
import bgImage from "../../../shared/assets/BG.webp";
import { Link } from "react-router-dom";
import { Avatar } from "@mui/material";
import ArrowRightSVG from "../../../shared/icons/ArrowRIghtSVG";
import LogoSVG from "../../../shared/icons/LogoSVG";
import { MainHeaderProps } from "./interface";
import { FC } from "react";
import Navbar from "../../Navbar/Navbar";
import { useTelegram } from "../../../shared/hooks/useTelegram";
import { useSpring } from "@react-spring/web";
import { animated } from "@react-spring/web";
import LinkButton from "../../LinkButton/LinkButton";
import { TG_ID } from "../../../shared/constants/AppOptions";
import React from "react";
import { useGetUserByTgIDQuery } from "../../../api/userAPI";
import { transformPathToPhoto } from "../../../shared/functions/transformPathToPhoto";
const MainHeader: FC<MainHeaderProps> = ({ children, className }) => {
  const { webApp } = useTelegram();
  // Хук для анимации
  const { transform } = useSpring({
    from: { transform: "translateY(-100%)" }, // Начальные значения
    to: { transform: "translateY(0)" }, // Конечные значения
    config: { tension: 200, friction: 20 }, // Настройки анимации
  });

  const { data } = useGetUserByTgIDQuery(
    webApp?.initDataUnsafe?.user?.id
      ? webApp?.initDataUnsafe?.user?.id
      : Number(TG_ID)
  );
  return (
    <animated.header className={`${styles.header} ${className}`}>
      <div className={styles.superContainer}>
        <div className={`${styles.top} ${styles.container}`}>
          <LinkButton
            animationClassName="slider"
            className={styles.linkAcc}
            to="/account"
          >
            <Avatar
              src={transformPathToPhoto(`${data?.photo}`)}
              sx={{ width: 47, height: 47 }}
            />
            <span>{webApp?.initDataUnsafe?.user?.first_name}</span>
            <ArrowRightSVG className={styles.arrow} />
          </LinkButton>

          <LogoSVG className={styles.logo} />
        </div>

        <div className={styles.container}>{children}</div>
      </div>

      <div className={styles.bg}>
        <img src={bgImage} className={styles.bg} alt={"bg"} />
      </div>
    </animated.header>
  );
};

export default React.memo(MainHeader);
