import Form from "../form/Form";
import { useEffect, useState } from "react";
import InputMask from "react-input-mask";
import {
  ErrorMessage,
  FormStepInterface,
} from "../../models/FormStepInterface";
import { FC } from "react";
import useVibration from "../../../../shared/hooks/useVibration";
import { createPortal } from "react-dom";
import { AlertComponent } from "../../../../ui/Alert/Alert";
import { AlertTitle } from "@mui/material";
import { Slide } from "@mui/material";
import styles from "./styles.module.scss";
import { useSendPhoneNumberDataMutation } from "../../../../api/activateAPI";
import { useAppDispatch } from "../../../../shared/hooks/reduxTypes";
import { setCode } from "../../slice/ActivateSlice";
import { useRef } from "react";
const NumberForm: FC<FormStepInterface> = ({ onSubmit }) => {
  const [phone, setPhone] = useState("");
  const [errorMessage, setErrorMessage] = useState<ErrorMessage>({
    status: false,
    message: "",
  });
  const [sendSMS] = useSendPhoneNumberDataMutation();
  const dispatch = useAppDispatch();
  const vibration = useVibration();
  const timerRef = useRef<NodeJS.Timeout | null>(null); // Создаем реф для таймера

  const inputRef = useRef<HTMLLabelElement | null>(null);

  const handleFocus = () => {
    if (inputRef.current) {
      inputRef.current.scrollIntoView({
        behavior: "smooth", // Плавная прокрутка
        block: "center", // Центрирует элемент по вертикали
        inline: "nearest", // Параметры прокрутки по горизонтали
      });
    }
  };

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setPhone(event.target.value);
  };

  const submitForm: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    const phoneNumberRegex = /^\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}$/;
    if (!phoneNumberRegex.test(phone)) {
      setErrorMessage({
        status: true,
        message: "Введи полностью номер телефона",
      });
      vibration.notificationOccurred("error");
      return;
    }

    // Подготовка номера телефона для отправки
    const formattedPhone = phone.replace(/[^+\d]/g, ""); // Удаляем все символы, кроме + и цифр

    sendSMS(formattedPhone).then((res) => {
      if (res.data) {
        dispatch(setCode(String(res.data.data.code)));
        onSubmit();
      }
    });
  };

  useEffect(() => {
    if (errorMessage.status === true) {
      // Устанавливаем новый таймер
      timerRef.current = setTimeout(() => {
        setErrorMessage((prev) => ({ ...prev, status: false }));
      }, 2000);
    }

    // Очищаем таймер при размонтировании или изменении errorMessage
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
        timerRef.current = null; // Обнуляем реф
      }
    };
  }, [errorMessage]);
  return (
    <>
      <Form
        headerText="ДОБРО ПОЖАЛОВАТЬ!"
        descriptionText="Введите ваш номер телефона:"
        onSubmit={submitForm}
      >
        <label ref={inputRef}>
          <InputMask
            onFocus={handleFocus}
            onBlur={() => window.scrollTo(0, 0)}
            type="tel"
            mask="+7 (999) 999-99-99"
            value={phone}
            onChange={handleChange}
            placeholder="+7 (___) ___-__-__"
            id="phone"
          />
        </label>
      </Form>

      {createPortal(
        <Slide
          style={{
            position: "fixed",
            top: 30,
            left: 20,
            right: 20,
            width: "auto",
            zIndex: 16,
          }}
          timeout={300}
          direction="down"
          in={errorMessage.status}
          mountOnEnter
          unmountOnExit
        >
          <AlertComponent severity="error">
            <>
              <AlertTitle className={styles.alertTitle}>
                {errorMessage.message}
              </AlertTitle>
            </>
          </AlertComponent>
        </Slide>,
        document.body
      )}
    </>
  );
};

export default NumberForm;
