import { Button } from "@mui/material";
import styles from "./styles.module.scss";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import FormSubmitButton from "../../../../ui/FormSubmitButton/FormSubmitButton";
import CounterEditor from "../../../../components/CounterEditor/CounterEditor";
import { useSpring, animated } from "@react-spring/web";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import {
  useGetUserByTgIDQuery,
  useLazyUpdateCartQuery,
  userApiSlice,
} from "../../../../api/userAPI";
import { TG_ID } from "../../../../shared/constants/AppOptions";
import { useGetHallByIdQuery } from "../../../../api/hallsAPI";
import { useAppDispatch } from "../../../../shared/hooks/reduxTypes";
import { useRef } from "react";
import {
  calcPrice,
  getHourForm,
} from "../../../../shared/functions/hallHelpers";
import { useTelegram } from "../../../../shared/hooks/useTelegram";
const HallForm = () => {
  const [{ transform }, api] = useSpring(() => ({
    from: { transform: "translateY(100%)" }, // Начальные значения
    to: { transform: "translateY(0)" }, // Конечные значения
    config: { duration: 500 }, // Настройки анимации
    delay: 250,
  }));

  const { id } = useParams();
  const { webApp } = useTelegram();

  const {
    data: hallData,
    isLoading: hallLoading,
    isError: hallIsError,
    error: hallError,
  } = useGetHallByIdQuery(String(id));

  const dispatch = useAppDispatch();
  const { data: userData } = useGetUserByTgIDQuery(
    webApp?.initDataUnsafe?.user?.id
      ? webApp?.initDataUnsafe?.user?.id
      : Number(TG_ID)
  );
  const [updateCart, { data, isLoading, isError }] = useLazyUpdateCartQuery();
  const [hour, setHour] = useState<number>(1);
  const typingTimerRef = useRef<NodeJS.Timeout | null>(null); // ref для таймера

  const updateCartWithDelay = (newHour: number) => {
    if (typingTimerRef.current) {
      clearTimeout(typingTimerRef.current);
    }
    typingTimerRef.current = setTimeout(() => {
      if (userData?.hall === hallData?.id && userData?.id) {
        updateCart({ booking_time: newHour, user_id: userData.id });
      }
    }, 1000);
  };

  const minusBtnClick: React.MouseEventHandler<HTMLButtonElement> = () => {
    if (hour > 1) {
      setHour(hour - 1);
      const update = userApiSlice.util.updateQueryData(
        "getUserByTgID",
        webApp?.initDataUnsafe?.user?.id
          ? webApp?.initDataUnsafe?.user?.id
          : Number(TG_ID),
        (draft) => {
          draft.booking_time -= 1;

          return draft;
        }
      );

      dispatch(update);
      updateCartWithDelay(hour - 1); // Передаем новое значение
    }
  };

  const plusBtnClick: React.MouseEventHandler<HTMLButtonElement> = () => {
    if (hour < 72) {
      setHour(hour + 1);
      const update = userApiSlice.util.updateQueryData(
        "getUserByTgID",
        webApp?.initDataUnsafe?.user?.id
          ? webApp?.initDataUnsafe?.user?.id
          : Number(TG_ID),
        (draft) => {
          draft.booking_time += 1;

          return draft;
        }
      );

      dispatch(update);
      updateCartWithDelay(hour + 1); // Передаем новое значение
    }
  };

  useEffect(() => {
    if (userData && hallData) {
      if (userData.hall === hallData.id) {
        setHour(userData.booking_time);
      }
    }
  }, [hallLoading]);

  useEffect(() => {
    return () => {
      if (typingTimerRef.current) clearTimeout(typingTimerRef.current);
    };
  }, []);

  if (hallData) {
    return (
      <animated.div style={{ transform }} className={styles.form}>
        <CounterEditor
          minusBtnClick={minusBtnClick}
          plusBtnClick={plusBtnClick}
          counterBlockChildren={
            <>
              {hour} {getHourForm(hour)} - {calcPrice(hour, hallData?.price)}{" "}
              руб
            </>
          }
        />

        <FormSubmitButton
          className={`${styles.selectBtn} ${
            userData?.hall === hallData?.id ? styles.disabled : ""
          }`}
          type="button"
          disabled={userData?.hall === hallData?.id}
          onClick={() => {
            if (userData && hallData) {
              updateCart({
                user_id: userData.id,
                hall: id,
                booking_time: hour,
              });

              const update = userApiSlice.util.updateQueryData(
                "getUserByTgID",
                webApp?.initDataUnsafe?.user?.id
                  ? webApp?.initDataUnsafe?.user?.id
                  : Number(TG_ID),
                (draft) => {
                  draft.hall = Number(id);
                  draft.booking_time = hour;
                  return draft;
                }
              );

              dispatch(update);
            }
          }}
        >
          {`${userData?.hall === hallData?.id ? "Зал выбран" : "Выбрать зал"}`}
        </FormSubmitButton>
      </animated.div>
    );
  }

  return <></>;
};

export default HallForm;
