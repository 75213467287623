import React, { useLayoutEffect, useState } from "react";
import "./App.scss";
import "./theme.css";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom"; // Импортируйте useLocation
import NewUserFormPage from "../pages/NewUserForm/NewUserFormPage";
import HallsPage from "../pages/Halls/HallsPage";
import HallDescriptionPage from "../pages/HallDescription/HallDescription";
import {
  TransitionGroup,
  CSSTransition,
  Transition,
} from "react-transition-group";
import MenuPage from "../pages/Menu/Menu";
import ProfilePage from "../pages/Profile/ProfilePage";
import OrderPage from "../pages/Order/Order";
import { useEffect } from "react";
import MainRoutes from "../routes/MainRoutes";
import { usePathname } from "../shared/hooks/usePathname";
import { usePageAnimationClassName } from "../shared/hooks/usePageAnimationClassName";
import { useGetUserByTgIDQuery } from "../api/userAPI";
import {
  BASE_URL_API,
  TG_ID,
  themeMapValue,
} from "../shared/constants/AppOptions";
import axios from "axios";
import useHistory from "../shared/hooks/useHistory";
import { useTheme } from "../shared/hooks/useTheme";
import { useTelegram } from "../shared/hooks/useTelegram";

const RouteTransition = () => {
  const location = useLocation(); // Получаем текущую локацию
  const { setValueTheme, theme } = useTheme();
  const { animationClassName } = usePageAnimationClassName();

  useEffect(() => {
    document.documentElement.setAttribute("data-theme", `${theme}`);
  }, [theme]);

  const pathname = usePathname();

  const func = () => {
    return pathname === "/menu" || pathname === "/" || pathname === "/order";
  };

  return (
    <>
      <TransitionGroup>
        <CSSTransition
          key={location.key}
          classNames={animationClassName}
          timeout={300}
        >
          <>
            <Routes location={location}>
              <Route path="/register" element={<NewUserFormPage />} />
              <Route path="/hallInfo/:id" element={<HallDescriptionPage />} />
              <Route path="/account" element={<ProfilePage />} />
            </Routes>

            {func() && (
              <div
                style={{
                  width: "100%",
                  minHeight: "100vh",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <MainRoutes />
              </div>
            )}
          </>
        </CSSTransition>
      </TransitionGroup>
    </>
  );
};

function App() {
  const { webApp } = useTelegram();
  const { data: userData, isLoading: userDataLoading } = useGetUserByTgIDQuery(webApp?.initDataUnsafe?.user?.id ? webApp?.initDataUnsafe?.user?.id :
    Number(TG_ID)
  );
  const pathname = usePathname();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    const style = document.createElement("style");
    style.type = "text/css";
    style.id = "dynamic-styles"; // Уникальный ID для стиля

    // Ваши правила CSS для селектора *
    const css = `
      * {
        transition: 0s; /* Пример изменения стиля для всех элементов */
      }`;
    style.appendChild(document.createTextNode(css));
    document.head.appendChild(style);

    // Удаляем стиль через 1 секунду после добавления
    const timer = setTimeout(() => {
      const styleElement = document.getElementById("dynamic-styles");
      if (styleElement) {
        document.head.removeChild(styleElement);
      }
    }, 1000); // Удаление стиля через 1000мс (1 секунда)

    // Функция очистки
    return () => {
      clearTimeout(timer); // Очищаем таймер

      // Удаляем стиль, если он еще существует
      const styleElement = document.getElementById("dynamic-styles");
      if (styleElement) {
        document.head.removeChild(styleElement);
      }
    };
  }, []);

  return <RouteTransition />;
}

export default App;
