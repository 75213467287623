import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ActivateSliceModel {
  code: null | string;
}

const initialState: ActivateSliceModel = {
  code: null,
};

const activateSlice = createSlice({
  name: "activateSlice",
  initialState,
  reducers: {
    setCode: (state, action: PayloadAction<string>) => {
      state.code = action.payload;
    },
  },
});

export const { setCode } = activateSlice.actions;

export default activateSlice.reducer;
