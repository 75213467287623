import OrderCard from "./OrderCard/OrderCard";
import styles from "./styles.module.scss";
import { OrderListProps } from "./interface";
import { FC, useRef, useEffect } from "react";
import {
  useGetUserByTgIDQuery,
  useLazyGetUserByTgIDQuery,
  useLazyUpdateCartQuery,
  userApiSlice,
} from "../../../../api/userAPI";
import { TG_ID } from "../../../../shared/constants/AppOptions";
import { useAppDispatch } from "../../../../shared/hooks/reduxTypes";
import { CartData } from "../../../../api/models/userAPIModel";
import { useTelegram } from "../../../../shared/hooks/useTelegram";

const OrderList: FC<OrderListProps> = ({
  orderData,
  cartData,
  minusClick,
  plusClick,
  clickDelete,
}) => {
  const {webApp} = useTelegram()
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const [updateCartMutation] = useLazyUpdateCartQuery();
  const { data: userData, isLoading: userDataIsLoading } =
    useGetUserByTgIDQuery(Number(TG_ID));

  const [fetchUser] = useLazyGetUserByTgIDQuery();

  const dispatch = useAppDispatch();

  const updateQuantity = () => {
    if (cartData.current && userData?.id)
      updateCartMutation({
        data: JSON.parse(JSON.stringify(cartData.current)),
        user_id: userData?.id,
      });
  };

  const handlePlusClick = (id: string, type: "food" | "services" | "goods") => {
    clearTimeout(timerRef.current!);
    const update = userApiSlice.util.updateQueryData(
      "getUserByTgID",
      webApp?.initDataUnsafe?.user?.id
        ? webApp?.initDataUnsafe?.user?.id
        : Number(TG_ID),
      (draft) => {
        const item = draft.data[type][id];

        if (item) {
          item.quantity = item.quantity + 1;
        }

        return draft;
      }
    );

    dispatch(update);

    if (cartData.current[type][id]) {
      cartData.current[type][id].quantity += 1;
    }

    if (plusClick) plusClick();
    timerRef.current = setTimeout(() => updateQuantity(), 1000); // Устанавливаем новый таймер
  };

  const clickDeleteCard = (id: string, type: "food" | "services" | "goods") => {
    clearTimeout(timerRef.current!);
    const update = userApiSlice.util.updateQueryData(
      "getUserByTgID",
      webApp?.initDataUnsafe?.user?.id
        ? webApp?.initDataUnsafe?.user?.id
        : Number(TG_ID),
      (draft) => {
        const item = draft.data[type];
        delete item[id];

        return draft;
      }
    );
    dispatch(update);

    const item = cartData.current[type];
    delete item[id];

    if (clickDelete) {
      clickDelete();
    }

    timerRef.current = setTimeout(() => updateQuantity(), 1000); // Устанавливаем новый таймер
  };

  const handleMinusClick = (
    id: string,
    type: "food" | "services" | "goods"
  ) => {
    clearTimeout(timerRef.current!); // Очищаем предыдущий таймер

    const update = userApiSlice.util.updateQueryData(
      "getUserByTgID",
      webApp?.initDataUnsafe?.user?.id
        ? webApp?.initDataUnsafe?.user?.id
        : Number(TG_ID),
      (draft) => {
        const item = draft.data[type][id];

        if (item) {
          item.quantity = item.quantity - 1;
        }

        return draft;
      }
    );

    dispatch(update);

    if (cartData.current[type][id]) {
      cartData.current[type][id].quantity -= 1;
    }

    if (minusClick) minusClick();
    timerRef.current = setTimeout(() => updateQuantity(), 1000); // Устанавливаем новый таймер
  };

  // Очистка таймера при размонтировании компонента
  useEffect(() => {
    return () => {
      clearTimeout(timerRef.current!); // Очистка таймера
    };
  }, []);

  return (
    <div className={styles.list}>
      {userData &&
        userData.data.food &&
        Object.entries(userData.data.food).map(([id, item]) => (
          <OrderCard
            className={styles.item}
            key={id}
            price={item.price}
            name={item.name}
            image={item.photo}
            count={item.quantity}
            plusClick={() => {
              if (item.quantity < 30) handlePlusClick(id, "food");
            }}
            minusClick={() => {
              if (item.quantity > 1) handleMinusClick(id, "food");
            }}
            onClickDelete={() => {
              clickDeleteCard(id, "food");
            }}
          />
        ))}

      {userData &&
        userData.data.services &&
        Object.entries(userData.data.services).map(([id, item]) => (
          <OrderCard
            className={styles.item}
            key={id}
            price={item.price}
            name={item.name}
            image={item.photo}
            count={item.quantity}
            plusClick={() => {
              if (item.quantity < 30) handlePlusClick(id, "services");
            }}
            minusClick={() => {
              if (item.quantity > 1) handleMinusClick(id, "services");
            }}
            onClickDelete={() => {
              clickDeleteCard(id, "services");
            }}
          />
        ))}

      {userData &&
        userData.data.goods &&
        Object.entries(userData.data.goods).map(([id, item]) => (
          <OrderCard
            className={styles.item}
            key={id}
            price={item.price}
            name={item.name}
            image={item.photo}
            count={item.quantity}
            plusClick={() => {
              if (item.quantity < 30) handlePlusClick(id, "goods");
            }}
            minusClick={() => {
              if (item.quantity > 1) handleMinusClick(id, "goods");
            }}
            onClickDelete={() => {
              clickDeleteCard(id, "goods");
            }}
          />
        ))}
    </div>
  );
};

export default OrderList;
