import BottomMenu, { Container } from "../../components/BottomMenu/BottomMenu";
import MainHeader from "../../components/header/main/MainHeader";
import HallList from "./components/HallList/HallList";
import HallsHeader from "./components/Header/Header";
import styles from "./styles.module.scss";
import { Modal as BaseModal, Slide } from "@mui/material";
import { useState } from "react";
import { styled, css } from "@mui/system";
import Fade from "@mui/material/Fade";
import * as React from "react";
import SwipeableDrawerComponent from "../../components/SwipebleDrawer/SwipeableDrawer";
import { AutorenewRounded } from "@mui/icons-material";
import SliderComponent from "../../ui/Slider/Slider";
import FilterForm from "./components/Filter/FilterForm";
import CustomDatePicker from "../../components/CustomDatePicker/CustomDatePicker";
import GiftModal from "./components/GIftModal/GiftModal";
import {
  useGetUserByTgIDQuery,
  useLazyGetPresentQuery,
} from "../../api/userAPI";
import { TG_ID } from "../../shared/constants/AppOptions";
import { useSelector } from "react-redux";
import { useAppSelector } from "../../shared/hooks/reduxTypes";
import { useRef } from "react";
import { useTelegram } from "../../shared/hooks/useTelegram";
const Halls = () => {
  const [open, setOpen] = useState(false);
  const [giftOpen, setGiftOpen] = useState(false);
  const timerRef = useRef<NodeJS.Timeout | null>(null); // Создаем реф для таймера
  const { webApp } = useTelegram();
  const { data: userData, isLoading: userDataLoading } = useGetUserByTgIDQuery(
    webApp?.initDataUnsafe?.user?.id
      ? webApp?.initDataUnsafe?.user?.id
      : Number(TG_ID)
  );
  const [getPresent, { data: presentData }] = useLazyGetPresentQuery();

  const viewPresent = useAppSelector((state) => state.hallsList.view_present);

  React.useEffect(() => {
    if (!viewPresent) {
      if (!userData?.got_present) {
        getPresent().then((res) => {
          if (res?.data) {
            timerRef.current = setTimeout(() => {
              setGiftOpen(true);
            }, 2000);
          }
        });
      }
    }
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);
  return (
    <>
      <div className={styles.container}>
        <HallsHeader
          onClickSettings={() => {
            setOpen(true);
          }}
        />
        <HallList />
      </div>

      <FilterForm
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        open={open}
      />

      <GiftModal
        onOpen={() => {
          setGiftOpen(true);
        }}
        onClose={() => {
          setGiftOpen(false);
        }}
        open={giftOpen}
      />
    </>
  );
};

export default Halls;
