import Select, {
  components,
  GroupBase,
  SelectComponentsConfig,
  OptionProps,
  MenuListProps,
} from "react-select";
import { SelectComponentProps } from "./interface";
import { FC } from "react";
import styles from "./styles.module.scss";
import "./select.scss"; // Импорт CSS
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";

const Option: React.ComponentType<OptionProps<any, false, GroupBase<any>>> = (
  props
) => {
  return <components.Option {...props} />;
};

const DropdownIndicator: FC<any> = (props) => {
  return (
    <ExpandMoreRoundedIcon
      sx={{ fontSize: 20, color: "var(--main-theme-color)" }}
    />
  );
};

const Menu: FC<any> = (props) => {
  return <components.Menu {...props} />;
};

const MenuList: React.ComponentType<
  MenuListProps<any, false, GroupBase<any>>
> = (props) => {
  return <components.MenuList {...props} />;
};

const MenuPortal = (props: any) => {
  return <components.MenuPortal {...props} />;
};
const SelectComponent: FC<SelectComponentProps> = ({
  options,
  placeholder,
  onChange,
  value,
}) => {

  return (
    <Select
      isSearchable={false}
      value={value}
  
      onChange={onChange}
      options={options}
      styles={{
        option: (base) => ({
          ...base,
          height: "100%",
          backgroundColor: "transparent",
        }),
        menu: (base) => ({
          ...base,
          padding: 0,
          background: "transparent",
          left: 0,
          marginTop: 4,
          borderRadius: 15,
          zIndex: 2,
        }),
        menuList: (base) => ({
          ...base,
          background: "var(--input-bg)",
          padding: 0,
          borderRadius: 15,
          overflow: "hidden",
        }),
        menuPortal: (base) => ({
          ...base,
          padding: 0,
          borderRadius: 15,
          background: "var(--input-bg)",
        }),
      }}
      placeholder={placeholder}
      classNamePrefix={"select"}
      className="select"
      components={{ DropdownIndicator, Option, MenuList, MenuPortal, Menu }}
    />
  );
};

export default SelectComponent;
