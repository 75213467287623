import { SwiperProps, Swiper, SwiperRef, SwiperSlide } from "swiper/react";
import { FC, RefObject, useEffect, useRef } from "react";
import MenuList from "../MenuList/MenuList";
import FoodsList from "../FoodsList/FoodsList";
import {
  useGetFoodsQuery,
  useGetGoodsQuery,
  useGetServicesQuery,
} from "../../../../api/productAPI";
import ServiceList from "../ServicesList/ServicesList";
import GoodsList from "../GoodsList/GoodsList";

interface SwiperMenuProps extends SwiperProps {
  swiperRef: RefObject<SwiperRef>;
  swiperValue: number;
}

const SwiperMenu: FC<SwiperMenuProps> = ({
  onSlideChange,
  swiperRef,
  swiperValue,
}) => {
  const swiperRefComponent = useRef<SwiperRef>(swiperRef.current);

  const {
    data: foodListData,
    isLoading: foodListIsLoading,
    isError: foodListIsError,
  } = useGetFoodsQuery(undefined, {});

  const {
    data: serviceListData,
    isLoading: serviceListIsLoading,
    isError: serviveListIsError,
  } = useGetServicesQuery(undefined, {});

  const {
    data: goodsListData,
    isLoading: goodsListIsLoading,
    isError: goodsListIsError,
  } = useGetGoodsQuery(undefined, {});

  return (
    <Swiper
      onSlideChange={onSlideChange}
      ref={swiperRef}
      autoHeight={false}
      cssMode
      speed={250}
      style={{ margin: 0, overflow: "auto" }}
      spaceBetween={30}
    >
      <SwiperSlide style={{ padding: "0 30px" }}>
        <FoodsList
          isLoading={foodListIsLoading}
          data={foodListData?.filtered}
          isError={foodListIsError}
        />
      </SwiperSlide>

      <SwiperSlide style={{ padding: "0 30px" }}>
        <ServiceList
          data={serviceListData?.filtered}
          isLoading={serviceListIsLoading}
          isError={serviveListIsError}
        />
      </SwiperSlide>

      <SwiperSlide style={{ padding: "0 30px" }}>
        <GoodsList
          data={goodsListData?.filtered}
          isLoading={goodsListIsLoading}
          isError={goodsListIsError}
        />
      </SwiperSlide>
    </Swiper>
  );
};

export default SwiperMenu;
