import { configureStore } from "@reduxjs/toolkit";
import { userApiSlice } from "../api/userAPI";
import { hallsApiSlice } from "../api/hallsAPI";
import { productApiSlice } from "../api/productAPI";
import FilterSlice from "../modules/Halls/slices/FilterSlice";
import { activateApi } from "../api/activateAPI";
import ActivateSlice from "../modules/NewUserForm/slice/ActivateSlice";
import HallsListSlice from "../modules/Halls/slices/HallsListSlice";
import OrderSlice from "../modules/Order/slice/OrderSlice";
import MenuFilterSlice from "../modules/Menu/slice/MenuFilterSlice";

export const store = configureStore({
  reducer: {
    [userApiSlice.reducerPath]: userApiSlice.reducer,
    [hallsApiSlice.reducerPath]: hallsApiSlice.reducer,
    [productApiSlice.reducerPath]: productApiSlice.reducer,
    [activateApi.reducerPath]: activateApi.reducer,
    hallsFilterForm: FilterSlice,
    activateInfo: ActivateSlice,
    hallsList: HallsListSlice,
    order: OrderSlice,
    menuFilterForm: MenuFilterSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      userApiSlice.middleware,
      hallsApiSlice.middleware,
      productApiSlice.middleware,
      activateApi.middleware
    ),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
