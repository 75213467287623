import { AlertTitle, Button, duration, FormControl } from "@mui/material";
import SectionHeader from "../../components/SectionHeader/SectionHeader";
import OrderList from "./components/OrderList/OrderList";
import Sale from "./components/Sale/Sale";
import styles from "./styles.module.scss";
import { useEffect, useMemo, useRef, useState } from "react";
import TimeInput from "../../components/Inputs/TimeInput/TimeInput";
import CustomDatePicker from "../../components/CustomDatePicker/CustomDatePicker";
import SelectComponent from "../../ui/Select/Select";
import AnimatedCard from "./components/AnimatedCard/AnimatedCard";
import AnimatedHeader from "./components/AnimatedHeader/AnimatedHeader";
import { useSpring, animated } from "@react-spring/web";
import { transform } from "typescript";
import AnimatedSubmitButton from "./components/AnimatedSubmitButton/AnimatedSubmitButton";
import FormSubmitButton from "../../ui/FormSubmitButton/FormSubmitButton";
import TimePicker from "../../components/TimePicker/TimePicker";
import {
  useCalcValueQuery,
  useClearCartMutation,
  useGetDateTimeQuery,
  useGetUserByTgIDQuery,
  useLazyCalcValueQuery,
  useLazySendOrderQuery,
  userApiSlice,
} from "../../api/userAPI";
import { TG_ID } from "../../shared/constants/AppOptions";
import {
  hallsApiSlice,
  useGetHallByIdQuery,
  useLazyCheckHallQuery,
} from "../../api/hallsAPI";
import HallOrder from "./components/HallOrder/HallOrder";
import { AlertMessage } from "../../shared/types/errorAlertMessage";
import { createPortal } from "react-dom";
import { Slide } from "@mui/material";
import { AlertComponent } from "../../ui/Alert/Alert";
import {
  CartData,
  UpdateCartFood,
  UpdateCartInfo,
  UpdateCartService,
  WebappDataFood,
  WebappDataGood,
  WebappDataHall,
  WebappDataRootOrder,
  WebappDataServices,
  WebappDataUser,
} from "../../api/models/userAPIModel";
import { useAppDispatch } from "../../shared/hooks/reduxTypes";
import LoadingScreen from "./components/Loading/Loading";
import { saleValue } from "./functions/saleValue";
import { useTelegram } from "../../shared/hooks/useTelegram";

const Order = () => {
  const dispatch = useAppDispatch();
  const { webApp } = useTelegram();
  const { data: nowDate, isLoading, isError } = useGetDateTimeQuery(undefined);

  const { data: userData } = useGetUserByTgIDQuery(Number(TG_ID));
  const [price, setPrice] = useState<number | null>(null);
  const [date, setDate] = useState<string>("");
  const [time, setTime] = useState<{ hours: number; minutes: number }>({
    hours: 0,
    minutes: 0,
  });
  const [formSubmitLoading, setFormSubmitLoading] = useState(false);
  const [checkHall] = useLazyCheckHallQuery();
  const [sendOrder] = useLazySendOrderQuery();
  const [clearCart] = useClearCartMutation();
  const cartDataRef = useRef<CartData>({ food: {}, services: {}, goods: {} });
  const hallDataRef = useRef({ price: 0, hours: 0 });
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const calculatePrice = () => {
    let priceValue = 0;
    priceValue += hallDataRef.current.price * hallDataRef.current.hours;

    const arrayFood = Object.entries(cartDataRef.current.food);
    const arrayServices = Object.entries(cartDataRef.current.services);
    const arrayGoods = Object.entries(cartDataRef.current.goods);

    for (let i = 0; i < arrayFood.length; i++) {
      let elem = arrayFood[i][1];
      priceValue += elem.quantity * elem.price;
    }

    for (let i = 0; i < arrayGoods.length; i++) {
      let elem = arrayGoods[i][1];

      priceValue += elem.quantity * elem.price;
    }

    for (let i = 0; i < arrayServices.length; i++) {
      let elem = arrayServices[i][1];
      priceValue += elem.price;
    }

    let sale = (priceValue * saleValue(hallDataRef.current.hours)) / 100;
    priceValue -= sale;
    return parseFloat(priceValue.toFixed(1));
  };

  const setCart = useMemo(() => {
    let info: UpdateCartInfo = {
      halls: {},
      food: {},
      services: {},
      goods: {},
    };

    if (userData) {
      const food = userData.data.food;
      let foodBody: Record<string, UpdateCartFood> = {};

      for (const key in food) {
        foodBody[key] = { quantity: food[key].quantity };
      }

      const goods = userData.data.goods;
      let goodBody: Record<string, UpdateCartFood> = {};

      for (const key in goods) {
        goodBody[key] = { quantity: goods[key].quantity };
      }

      const services = userData.data.services;
      let servicesBody: Record<string, UpdateCartService> = {};

      for (const key in services) {
        servicesBody[key] = {};
      }
      if (userData.booking_time && userData.hall && info.halls) {
        info.halls[String(userData.hall)] = {
          hours: userData?.booking_time,
        };
      }

      info.food = foodBody;
      info.goods = goodBody;
      info.services = servicesBody;
    }

    return info;
  }, []);

  const { data: cartData, isLoading: cartLoading } = useCalcValueQuery(
    setCart,
    { skip: userData ? false : true }
  );

  const { data: dateData } = useGetDateTimeQuery(undefined);

  const [alertMessage, setAlertMessage] = useState<AlertMessage>({
    status: false,
    message: "",
    severity: "error",
  });

  useEffect(() => {
    if (alertMessage.status) {
      timerRef.current = setTimeout(() => {
        setAlertMessage((prev) => ({ ...prev, status: false }));
      }, 2000);
    }
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [alertMessage.status]);

  const checkClick = () => {
    if (!userData?.hall) {
      setAlertMessage({
        status: true,
        message: "Выберите зал",
        severity: "error",
      });
    } else {
      if (cartData?.halls && Object.entries(cartData?.halls)[0]) {
        checkHall({
          id: Object.entries(cartData?.halls)[0][0],
          hours: userData?.booking_time ? userData?.booking_time : 0,
          date: date,
          time: `${time.hours}:${time.minutes}`,
        }).then((res) => {
          if (res.data) {
            if (res.data.available === false) {
              setAlertMessage({
                status: true,
                message: "Зал недоступен на это время",
                severity: "error",
              });
            } else {
              setAlertMessage({
                status: true,
                message: "Зал доступен на это время",
                severity: "success",
              });
            }
          }
        });
      }
    }
  };

  useEffect(() => {
    if (cartData) {
      let fInfo = cartData.food;
      let gInfo = cartData.goods;
      let sInfo = cartData.services;
      let foodsInfo: CartData["food"] = {};
      let servicesInfo: CartData["services"] = {};
      let goodsInfo: CartData["goods"] = {};
      for (const key in fInfo) {
        let elem = fInfo[key];
        foodsInfo[key] = {
          name: elem.name,
          weight: elem.weight,
          price: elem.price_per_unit,
          quantity: elem.quantity,
          photo: elem.photo,
        };
      }
      for (const key in gInfo) {
        let elem = gInfo[key];
        goodsInfo[key] = {
          name: elem.name,
          weight: elem.weight,
          price: elem.price_per_unit,
          quantity: elem.quantity,
          photo: elem.photo,
        };
      }
      for (const key in sInfo) {
        let elem = sInfo[key];
        servicesInfo[key] = {
          name: elem.name,
          time: elem.for_time,
          price: elem.total_price,
          quantity: elem.quantity,
          photo: elem.photo,
        };
      }

      foodsInfo = JSON.parse(JSON.stringify(foodsInfo));
      goodsInfo = JSON.parse(JSON.stringify(goodsInfo));

      servicesInfo = JSON.parse(JSON.stringify(servicesInfo));
      cartDataRef.current = JSON.parse(
        JSON.stringify({
          food: foodsInfo,
          services: servicesInfo,
          goods: goodsInfo,
        })
      ) as CartData;

      if (cartData.halls) {
        const hallInfo = Object.entries(cartData.halls)[0]
          ? Object.entries(cartData.halls)[0][1]
          : undefined;

        if (hallInfo) {
          hallDataRef.current = {
            hours: hallInfo.hours,
            price: hallInfo.price_per_hour,
          };
        }
      }

      setPrice(calculatePrice());

      const update = userApiSlice.util.updateQueryData(
        "getUserByTgID",
        webApp?.initDataUnsafe?.user?.id
          ? webApp?.initDataUnsafe?.user?.id
          : Number(TG_ID),
        (draft) => {
          draft.data.food = foodsInfo;
          draft.data.goods = goodsInfo;
          draft.data.services = servicesInfo;
          return draft;
        }
      );
      dispatch(update);
    }
  }, [cartLoading]);

  useEffect(() => {
    if (dateData?.datetime) {
      let dateVal = new Date(dateData?.datetime);

      if (dateVal) {
        setDate(
          `${dateVal.getFullYear()}-${
            dateVal.getMonth() + 1
          }-${dateVal.getDate()}`
        );
      }
    }
  }, [dateData]);

  useEffect(() => {
    const date = new Date();
    const selectedHour = date.getHours();
    const selectedMinute = date.getMinutes();

    let state: { hours: number; minutes: number } = { hours: 0, minutes: 0 };

    if (selectedMinute === 0 || selectedMinute === 30) {
      state.hours = selectedHour;
      state.minutes = selectedMinute;

      setTime(state);
      return;
    }
    if (selectedMinute < 30) {
      state.hours = selectedHour;
      state.minutes = 30;

      setTime(state);
      return;
    }
    if (selectedMinute > 30 && selectedMinute < 60) {
      state.hours = selectedHour + 1 >= 24 ? 0 : selectedHour + 1;
      state.minutes = 0;

      setTime(state);
      return;
    }
  }, []);

  const formSubmit = async () => {
    setFormSubmitLoading(true);
    if (cartData && userData) {
      if (cartData?.halls && Object.entries(cartData?.halls)[0] === undefined) {
        
        setAlertMessage({
          status: true,
          message: "Выберите зал",
          severity: "error",
        });

        setFormSubmitLoading(false);

        return;
      }

      if (cartData?.halls) {
        const reqCheckHall = await checkHall({
          id: Object.entries(cartData?.halls)[0][0],
          hours: userData?.booking_time ? userData?.booking_time : 0,
          date: date,
          time: `${time.hours}:${time.minutes}`,
        });

        if (reqCheckHall.data?.available === false) {
          setAlertMessage({
            status: true,
            message: "Зал недоступен на это время",
            severity: "error",
          });
          setFormSubmitLoading(false);
        } else {
          const userInfo: WebappDataUser = {
            tg_id: webApp?.initDataUnsafe?.user?.id
              ? webApp?.initDataUnsafe?.user?.id
              : Number(TG_ID),
            phone: String(userData?.phone_number),
          };
          const cartHallInfo = Object.entries(cartData.halls)[0];
          let hallInfo: Record<string, WebappDataHall> = {};

          const dateValue = new Date(date);

          const dateValueStr = `${dateValue.getDate()}.${
            dateValue.getMonth() + 1
          }.${dateValue.getFullYear() % 1000}, ${time.hours}:${time.minutes}`;

          if (cartHallInfo) {
            hallInfo[String(cartHallInfo[0][0])] = {
              name: "",
              datetime: dateValueStr,
              hours: String(userData?.booking_time),
            };
          }

          const dataFood: Record<string, WebappDataFood> = {};
          const dataGood: Record<string, WebappDataGood> = {};
          const dataServices: Record<string, WebappDataServices> = {};

          if (userData) {
            const foodList = userData.data.food;

            for (let key in foodList) {
              let elem = foodList[key];
              dataFood[key] = { quantity: elem.quantity };
            }

            const goodList = userData.data.goods;

            for (let key in goodList) {
              let elem = goodList[key];
              dataGood[key] = { quantity: elem.quantity };
            }

            const servicesList = userData.data.services;

            for (let key in servicesList) {
              let elem = servicesList[key];
              dataServices[key] = { quantity: 1 };
            }
          }

          let orderInfo: WebappDataRootOrder = {
            Order: {
              user: userInfo,
              info: {
                halls: hallInfo,
                foods:
                  Object.entries(dataFood).length === 0 ? undefined : dataFood,
                goods:
                  Object.entries(dataGood).length === 0 ? undefined : dataGood,
                services:
                  Object.entries(dataServices).length === 0
                    ? undefined
                    : dataServices,
              },
            },
          };

          const reqSendOrder = await sendOrder(orderInfo);

          if (reqSendOrder.data && userData.id) {
            await clearCart(String(userData.id));
            webApp.close();
          }

          setFormSubmitLoading(false);
        }
      }
    }
  };

  if (cartLoading) {
    return <LoadingScreen />;
  }

  return (
    <>
      <form
        style={{ flex: "1 0 auto", display: "flex", flexDirection: "column" }}
        onSubmit={(e) => {
          e.preventDefault();
          formSubmit();
        }}
      >
        <div className={styles.container}>
          <AnimatedHeader>ВЫБРАННЫЙ ЗАЛ:</AnimatedHeader>

          <HallOrder
            plusHourClick={() => {
              hallDataRef.current.hours += 1;
              setPrice(calculatePrice());
            }}
            minusHourClick={() => {
              hallDataRef.current.hours -= 1;
              setPrice(calculatePrice());
            }}
            data={cartData?.halls}
            isLoading={cartLoading}
          />

          <AnimatedHeader>ТОВАРЫ И УСЛУГИ:</AnimatedHeader>

          <Sale />

          <OrderList
            plusClick={() => {
              setPrice(calculatePrice());
            }}
            minusClick={() => {
              setPrice(calculatePrice());
            }}
            clickDelete={() => {
              setPrice(calculatePrice());
            }}
            cartData={cartDataRef}
          />

          <SectionHeader>БРОНИРОВАНИЕ:</SectionHeader>

          <CustomDatePicker
            onChangeDate={(dateVal) => {
              setDate(
                `${dateVal.getFullYear()}-${
                  dateVal.getMonth() + 1
                }-${dateVal.getDate()}`
              );
            }}
            dateValue={dateData?.datetime ? new Date(date) : new Date()}
            className={styles.datepicker}
          />
          <div
            className={styles.lastElem}
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              justifyContent: "space-between",
              gap: 10,
              marginTop: 30,
            }}
          >
            <div className={styles.column}>
              <span className={styles.label}>Время:</span>

              <TimePicker
                className={styles.input}
                hours={time.hours}
                minutes={time.minutes}
                onChangeHours={(val) => {
                  setTime({ ...time, hours: val });
                }}
                onChangeMinutes={(val) => {
                  setTime({ ...time, minutes: val });
                }}
                openSelectClassName={styles.activeTimePickerSelect}
              />
            </div>

            <FormSubmitButton
              onClick={checkClick}
              className={styles.column}
              type="button"
            >
              <>Проверить</>
            </FormSubmitButton>
          </div>
        </div>

        {price !== null && (
          <AnimatedSubmitButton
            disabled={formSubmitLoading}
            type="submit"
            className={styles.submitBtn}
          >
            {`ОФОРМИТЬ ЗАКАЗ (${price}р.)`}
          </AnimatedSubmitButton>
        )}
      </form>

      {createPortal(
        <Slide
          style={{
            position: "fixed",
            top: 15,
            left: 20,
            right: 20,
            width: "auto",
            zIndex: 16,
          }}
          timeout={300}
          direction="down"
          in={alertMessage.status}
          mountOnEnter
          unmountOnExit
        >
          <AlertComponent severity={alertMessage.severity}>
            <>
              <AlertTitle className={styles.alertTitle}>
                {alertMessage.message}
              </AlertTitle>
            </>
          </AlertComponent>
        </Slide>,
        document.body
      )}
    </>
  );
};

export default Order;
