import SectionHeader from "../../../../components/SectionHeader/SectionHeader";
import styles from "../../styles.module.scss";
import InfoListBlock from "../../../../components/InfoListBlock/InfoListBlock";
import PhoneSVG from "../../../../shared/icons/PhoneSVG";
import { Button } from "@mui/material";
import { SupportProps } from "./interface";
import { FC } from "react";
import CopySVG from "../../../../shared/icons/CopySVG";
import { useGetSupportPhoneQuery } from "../../../../api/userAPI";
import useVibration from "../../../../shared/hooks/useVibration";
const Support: FC<SupportProps> = ({ copyClick }) => {
  const { data } = useGetSupportPhoneQuery();
  const vibration = useVibration();
  return (
    <div className={styles.container}>
      <SectionHeader className={styles.sectionHeaderSupport}>
        ПОДДЕРЖКА:
      </SectionHeader>
      <InfoListBlock className={`${styles.profileInfo} ${styles.lastElem}`}>
        <>
          {data &&
            data.map((el) => {
              return (
                <li key={el.id} className={`${styles.phone} ${styles.support}`}>
                  <PhoneSVG />
                  <span className={styles.text}>{el.phone}</span>
                  <Button
                    onClick={(e) => {
                      if (copyClick) copyClick(e);

                      try {
                        navigator.clipboard.writeText(el.phone);
                        vibration.notificationOccurred("success");
                      } catch (err) {
                        vibration.notificationOccurred("error");
                      }
                    }}
                  >
                    <CopySVG />
                  </Button>
                </li>
              );
            })}
        </>
      </InfoListBlock>
    </div>
  );
};

export default Support;
