import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL_API } from "../shared/constants/AppOptions";
import {
  CartUpdateAPIParams,
  HistoryListItem,
  OrderDetails,
  PresentInfo,
  SupportAPIModel,
  UpdateCartInfo,
  UserModelAPI,
  WebappDataRootOrder,
} from "./models/userAPIModel";
import { DateTimeApiModel } from "./models/dateTimeApiModel";
import { TG_ID } from "../shared/constants/AppOptions";
import { transformPathToPhoto } from "../shared/functions/transformPathToPhoto";
export const userApiSlice = createApi({
  reducerPath: "user",
  tagTypes: ["User"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL_API}`,
  }),
  endpoints: (builder) => ({
    getUserByTgID: builder.query<UserModelAPI, number>({
      query: (tgId: number) => `users/get_by_tg_id?tg_id=${tgId}`,
      keepUnusedDataFor: 2000,
      providesTags: ["User"],
      transformResponse: (res: UserModelAPI) => {
        if (res.data === null) {
          res.data = { food: {}, services: {}, goods: {} };
        }
        return res;
      },
    }),
    getDateTime: builder.query<DateTimeApiModel, undefined>({
      query: () => `users/get_datetime/`,
    }),
    updateCart: builder.query<UserModelAPI, CartUpdateAPIParams>({
      query: (cartData) => {
        const body: Omit<CartUpdateAPIParams, "user_id" | "book_time"> = {
          hall: cartData.hall,
          booking_time: cartData.booking_time,
          data: cartData.data,
          user_theme: cartData?.user_theme,
        };
        return {
          url: `users/${cartData.user_id}/`,
          method: "PUT",
          body: { ...body, tg_id: TG_ID },
        };
      },
    }),
    clearCart: builder.mutation<UserModelAPI, string>({
      query: (params) => {
        return { url: `users/${params}/clear_cart`, method: "DELETE" };
      },
      invalidatesTags: ["User"],
    }),
    getSupportPhone: builder.query<SupportAPIModel[], void>({
      query: () => "/support",
    }),
    getHistory: builder.query<HistoryListItem[], string | number>({
      query: (params) => `history/history_by_id/?user_id=${params}`,
      transformResponse: (data: HistoryListItem[]) => {
        data = data.map((el) => {
          el.photo = transformPathToPhoto(el.photo);

          return el;
        });
        return data;
      },
    }),
    getPresent: builder.query<PresentInfo[], void>({
      query: () => "/presents/",
      keepUnusedDataFor: Infinity,
    }),
    calcValue: builder.query<OrderDetails, UpdateCartInfo | undefined>({
      query: (params) => {
        return {
          url: "/users/calculate_order/",
          method: "POST",
          body: {
            info: params,
          },
        };
      },
    }),
    sendOrder: builder.query<any, WebappDataRootOrder>({
      query: (params) => {
        return {
          url: "/webappdata/",
          method: "POST",
          body: { order_data: params },
        };
      },
    }),
  }),
});

export const {
  useGetUserByTgIDQuery,
  useLazyGetUserByTgIDQuery,
  useGetDateTimeQuery,
  useUpdateCartQuery,
  useLazyUpdateCartQuery,
  useGetSupportPhoneQuery,
  useLazyGetPresentQuery,
  useGetPresentQuery,
  useGetHistoryQuery,
  useLazyGetHistoryQuery,
  useCalcValueQuery,
  useLazyCalcValueQuery,
  useLazySendOrderQuery,
  useClearCartMutation,
} = userApiSlice;
